import React, { useEffect } from "react";
import { Button, Layout, theme } from "antd";
import classNames from "classnames";
import { RiMenu2Fill, RiMenuFoldLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { MailOutlined } from "@ant-design/icons";
// import PayModal from "../pages/Home/Partials/PayModal";
// import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import Settings from "./partials/Settings";
import UserMenu from "./partials/UserMenu";
import LogoSrc from "../../assets/images/Abby_3x.png";
import smLogoSrc from "../../assets/images/Abby_3x.png";
import constants from "../../config/constants";
import { setDarkMode, setOpenPayModal, setOpenSide } from "../../redux/app/appSlice";
import { getPlans } from "../../redux/plan/planSlice";

const { useToken } = theme;
const { Header, Footer } = Layout;

const stripePromise = loadStripe(constants.stripePK);

function AuthLayout({ children }) {
  const { token } = useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isDarkMode = useSelector((state) => state.app.isDarkMode);
  const openSide = useSelector((state) => state.app.openSide);
  const collapsed = useSelector((state) => state.app.collapsed);
  const openPayModal = useSelector((state) => state.app.openPayModal);
  const user = useSelector((state) => state.auth.user);
  const plans = useSelector((state) => state.plan.plans ?? []);

  useEffect(() => {
    if (plans.length === 0) {
      dispatch(getPlans());
    }
  }, []);

  const changeTheme = () => {
    dispatch(setDarkMode());
  };

  const setOpenModal = () => {
    dispatch(setOpenPayModal());
  };

  return (
    <Layout className="min-h-screen">
      {location.pathname != '/playground' && <Header
        className={classNames(`shadow-lg sticky px-0 top-0 z-[999] bg-primary`)}
      >
        <div className="flex items-center justify-between px-4 mx-auto max-w-7xl">
          <div
            className={classNames(
              "demo-logo h-[64px] flex items-center"
              // !isDarkMode && "bg-white"
            )}
          >
            {/* {(collapsed && location.pathname == '/playground') && <Button icon={openSide ? <RiMenuFoldLine className="text-lg" /> : <RiMenu2Fill className="text-lg" />} className="!text-white" type="link" onClick={() => {
              dispatch(setOpenSide(!openSide));
            }}></Button>} */}
            <Link to="/lists" className="hidden sm:inline">
              <img
                src={LogoSrc}
                alt="logo"
                className={classNames(
                  "w-[96px] p-3 text-white"
                  // !isDarkMode && "invert"
                )}
              />
            </Link>
            <Link to="/lists" className="inline sm:hidden">
              <img
                src={smLogoSrc}
                alt="logo"
                className={classNames(
                  "w-[96px] p-3 text-white"
                  // !isDarkMode && "invert"
                )}
              />
            </Link>
          </div>
          <div className="flex items-center">
            <div>
              <Link to="/playground" className="mr-4 text-white sm:mr-6">
                Chat
              </Link>
            </div>
            {/* <div className="mr-2 sm:mr-6">
                <Button type="primary" icon={<FileAddOutlined />} onClick={() => {
                  // return navigate('/home');
                  if (user.activeSubscription && user.activeSubscription.status == 'active') {
                    return navigate('/home');
                  }
                  if (user.activeSubscription && user.activeSubscription.status != 'active') {
                    Modal.confirm({
                      title: 'Your subscription past due.',
                      icon: <ExclamationCircleFilled />,
                      content: 'Will you continue it?',
                      okText: "Yes",
                      cancelText: "No",
                      onOk() {
                        window.open(user.activeSubscription.hosted_invoice, '_blank');
                        return new Promise((resolve, reject) => {
                          setTimeout(resolve, 1000);
                        }).catch(() => console.log('Oops errors!'));
                      },
                      onCancel() {
                        console.log('Cancel');
                      },
                    });
                  } else {
                    setOpenModal();
                  }
                }}>New Project</Button>
              </div> */}
            <UserMenu />
          </div>
        </div>
      </Header>}
      {/* <div onClick={() => {
          navigate(-1);
        }} className={classNames("sticky w-[75px] cursor-pointer top-20 -ml-11 hover:ml-0 ease-in-out transition-[margin] z-[998] px-2.5 py-2 rounded-e-3xl", isDarkMode ? "bg-gray-100" : "bg-gray-300")}>
          <span>Back</span> &nbsp; <ArrowLeftOutlined />
        </div> */}
      <Layout /* className="h-[1000px]" */ className={classNames("bg-primary", location.pathname != '/playground' && "pb-10")}>
        {children}
      </Layout>
      {/* <ScrollToTop smooth className="animate-bounce" /> */}
      {/* <FloatButton.BackTop /> */}
      {/* <Settings /> */}
      {/* <Elements stripe={stripePromise} nonce="random-nonce">
        <PayModal
          open={openPayModal}
          setOpen={setOpenModal}
          price={plans[0]?.price}
          planId={plans[0]?._id}
          setSuccessful={() => { }}
        />
      </Elements> */}
      {location.pathname != '/playground' && <Footer>
        <div className="max-w-7xl mx-auto px-4">
          <div>
            <Link to="/lists" className="">
              <img
                src={LogoSrc}
                alt="logo"
                className={classNames(
                  "w-[96px] p-3 text-white invert"
                  // !isDarkMode && "invert"
                )}
              />
            </Link>
          </div>
          <div className="flex items-center justify-between">
            <div className="">
              <Link to={'/playground'}><span className="text-gray-500 mx-4">AI therapy</span></Link>
              <a href={'https://abby.gg/blog'} target="_blank"><span className="text-gray-500 mx-4">Blog</span></a>
              <a href={'https://abby.gg/terms-conditions'} target="_blank"><span className="text-gray-500 mx-4">Terms & Conditions</span></a>
              <a href={'https://abby.gg/privacy-policy'} target="_blank"><span className="text-gray-500 mx-4">Privacy Policy</span></a>
              <a href={'mailto:hello@abby.gg'}><span className="text-gray-500 mx-4">Contact Us</span></a>
            </div>
            <div>
              <a href="mailto:hello@abby.gg"><Button icon={<MailOutlined />} shape="circle"></Button></a>
            </div>
          </div>
        </div>
      </Footer>}
    </Layout>
  );
}

export default AuthLayout;
