import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

function PayedRoute() {
  const location = useLocation();

  const isPayed = useSelector((state) => state.auth.user.activeSubscription);

  return isPayed?.status == 'active' ? <Outlet /> : <Navigate to="/lists" />;
}

export default PayedRoute;
