import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loader: false,
  selectedAiKey: 'general',
}

const threadSlice = createSlice({
  name: 'threads',
  initialState,
  reducers: {
    setSelectedAiKey(state, action) {
      state.selectedAiKey = action.payload;
    },
  },
});

export const { setSelectedAiKey } = threadSlice.actions;
export default threadSlice.reducer;
