import { createSlice } from "@reduxjs/toolkit";
import { getStorage, setStorage } from "../../helpers";

const initialState = {
    isDarkMode: false,
    openPayModal: false,
    collapsed: false,
    openSide: false,
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setDarkMode(state, action) {
            state.isDarkMode = !state.isDarkMode;
        },
        setOpenPayModal(state, action) {
            state.openPayModal = !state.openPayModal;
        },
        setCollapsed(state, action) {
            state.collapsed = action.payload;
        },
        setOpenSide(state, action) {
            state.openSide = action.payload;
        }
    },
});

export const { setDarkMode, setOpenPayModal, setCollapsed, setOpenSide } = appSlice.actions;

export default appSlice.reducer;
