import { lazy } from 'react';

const routes = [
  {
    path: 'home',
    component: lazy(() => import('../pages/Home/Lists')),
    exact: true,
    requireAuthLayout: false,
  },
  {
    path: 'playground',
    component: lazy(() => import('../pages/Home/Playground')),
    exact: true,
    requireAuthLayout: true,
    // isPayed: true
  },
  {
    path: 'lists',
    component: lazy(() => import('../pages/Home/Lists')),
    exact: true,
    requireAuthLayout: true,
    // isPayed: true
  },
  {
    path: 'playground/:id',
    component: lazy(() => import('../pages/Home/Playground')),
    isAdmin: true,
    exact: true,
    requireAuthLayout: true,
    // isPayed: true
  },
  {
    path: 'user/profile',
    component: lazy(() => import('../pages/Profile/Edit')),
    exact: true,
    requireAuthLayout: true,
  },
  {
    path: 'admin/users',
    component: lazy(() => import('../pages/Admin/Users')),
    exact: true,
    isAdmin: true,
    requireAuthLayout: true,
  },
  {
    path: 'admin/conversations',
    component: lazy(() => import('../pages/Admin/Conversations')),
    exact: true,
    isAdmin: true,
    requireAuthLayout: true,
  },
  {
    path: 'admin/analysis/:id',
    component: lazy(() => import('../pages/Admin/Analysis')),
    exact: true,
    isAdmin: true,
    requireAuthLayout: true,
  },
];

export default routes;
