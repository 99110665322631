
const constants = {
  dev: {
    HOST_URL: 'http://localhost:5000/api/',
    stripePK: 'pk_test_51MqDHeIhTKltFnGq3WGCHjZi5ZfUg5T8aiGVPa7XFZcrse4SGLvTjDbXDNb5inKjlOaJqHJRDv5HsPQ6tbnfsxRL00rwetwKzu',
    SOCKET_URL: 'http://localhost:5000',
    FREE_CREDIT: 20,
  },
  prod: {
    HOST_URL: process.env.REACT_APP_HOST_URL || '/api/',
    stripePK: 'pk_live_51PLuKzKiF66IIHL1z9Q5X6zKVFgVhIdmyxtT4h21CFNsMtnj4Iedhx2xDoD0S4t55zM3x3vRmz21DguTK4IxhmRm00gx3PWxSg',
    SOCKET_URL: '',
    FREE_CREDIT: 20,
  }
};

const REACT_APP_ENV = process.env.REACT_APP_ENV || "dev";

export const lists = {
  therapy: [
    {
      key: 'general',
      title: 'General Therapy',
      count: "1.5m",
      desc: `Welcome to your General Therapy session. We’re here to discuss whatever is on your mind and find ways to support your overall well-being. What issues or topics would you like to focus on today?`,
    },
    {
      key: 'anxiety_stress',
      title: 'Anxiety & Stress',
      count: "1.4m",
      desc: `Welcome to your Anxiety and Stress Management session. Let's work on techniques to help you manage your anxiety and reduce stress. Can you share what situations or thoughts trigger your anxiety most frequently?`
    },
    {
      key: 'bullying',
      title: 'Bullying',
      count: "1.1m",
      desc: `Welcome to your Bullying Therapy session. Let's work on understanding and overcoming the impact of bullying on your life. Can you share more about your experiences with bullying and how it's affecting you?`
    },
    {
      key: 'depression',
      title: 'Depression',
      count: "690k",
      desc: `Welcome to your Depression Therapy session. Together, we will explore ways to lift your mood and regain a sense of hope and motivation. Can you describe how you’ve been feeling lately and any specific challenges you’re facing?`
    },
    {
      key: 'relationship_therapy',
      title: 'Relationship',
      count: "1.5m",
      desc: `Welcome to your Relationship Therapy session. Let’s focus on improving communication and resolving conflicts in your relationship. What relationship are you having problems with, and what issues are you currently experiencing?`
    },
    {
      key: 'trauma_PTSD',
      title: 'Trauma & PTSD',
      count: "1.2m",
      desc: `Welcome to your Trauma and PTSD Therapy session. We are here to help you process your traumatic experiences and find ways to heal. Can you share any specific events that have been troubling you?`
    },
    {
      key: 'self_esteem',
      title: 'Self-Esteem',
      count: "880k",
      desc: `Welcome to your Self-Esteem and Self-Worth session. Let's work on building a positive self-image and increasing your confidence. Can you describe situations where you feel most challenged in valuing yourself?`
    },
    {
      key: 'life_transitions',
      title: 'Life Transitions',
      count: "1.1m",
      desc: `Welcome to your Life Transitions Therapy session. We’re here to support you through significant changes and help you adapt. What recent changes in your life are you finding most difficult to navigate?`
    },
    {
      key: 'greif_loss',
      title: 'Greif & Loss',
      count: "380k",
      desc: `Welcome to your Grief and Loss Therapy session. Let's explore your feelings and find ways to cope with your loss. Can you share more about your recent loss and how it’s affecting you?`
    },
    {
      key: 'addiction_substanceabuse',
      title: 'Addiction & Substance Abuse',
      count: "910k",
      desc: `Welcome to your Addiction and Substance Abuse session. Let’s work together on overcoming your addiction and finding healthier coping mechanisms. Can you tell me more about your substance use and how it impacts your life?`
    },
    {
      key: 'family_issues',
      title: 'Family Issues',
      count: "1.3m",
      desc: `Welcome to your Family Therapy session. Let's work on improving communication and resolving conflicts within your family. Who would you like to include in today's session, and what issues are you facing together?`
    },
    {
      key: 'sexual_issues',
      title: 'Sexual Issues',
      count: "770k",
      desc: `Welcome to your Sexual Issues Therapy session. Let's address your concerns and work towards a healthier sexual life. Can you share what specific sexual issues you’re experiencing?`
    },
    {
      key: 'eating_disorders',
      title: 'Eating Disorders',
      count: "1.1m",
      desc: `Welcome to your Eating Disorder Therapy session. Together, we will work on developing a healthier relationship with food and your body. Can you tell me more about your eating habits and how you feel about them?`
    },
    {
      key: 'anger_management',
      title: 'Anger Management',
      count: "1.2m",
      desc: `Welcome to your Anger Management Therapy session. Let’s explore healthier ways to express and control your anger. Can you describe situations where you find it difficult to manage your anger?`
    },
  ],
  coaches: [
    {
      key: 'spiritual',
      title: 'Spiritual Coach',
      count: "1.4m",
      desc: `Welcome to your Spiritual Coaching session. Let’s delve into your spiritual journey and find ways to deepen your connection. What aspects of your spirituality would you like to explore or develop?`
    },
    {
      key: 'journaling',
      title: 'Journaling Coach',
      count: "780k",
      desc: `Welcome to your Journaling Coaching session. Let’s explore how journaling can help you reflect and grow. What goals do you have for your journaling practice?`
    },
    {
      key: 'astrology',
      title: 'Astrology Coach',
      count: "1.1m",
      desc: `Welcome to your Astrology Coaching session. Let's use the insights from astrology to guide you in your personal growth and decision-making. What areas of your life would you like to focus on with the help of astrology?`
    },
    {
      key: 'dating',
      title: 'Dating Coach',
      count: "1.4m",
      desc: `Welcome to your Dating Coaching session. Let's work on improving your dating skills and finding meaningful connections. What challenges are you currently facing in your dating life?`
    },
    {
      key: 'relationship',
      title: 'Relationship Coach',
      count: "590k",
      desc: `Welcome to your Relationship Coaching session. Let's focus on enhancing your relationships and resolving any conflicts. What specific relationship issues would you like to address today?`
    },
    {
      key: 'positivity',
      title: 'Positivity Coach',
      count: "360k",
      desc: `Welcome to your Positivity Coaching session. Let's focus on cultivating a positive mindset and enhancing your overall happiness. What problems are you currently facing that you think could benefit from a more positive outlook?`
    },
    {
      key: 'mental_toughness',
      title: 'Mental Toughness Coach',
      count: "830k",
      desc: `Welcome to your Mental Toughness Coaching session. Let's work on building your resilience and mental strength to tackle life's challenges. What specific problems are you currently facing that you want to address with greater mental toughness?`
    },
  ],
  philosophy: [
    {
      key: 'stoic',
      title: 'Stoic Philosophy',
      count: "1.4m",
      desc: `Welcome to your Stoic Philosophy session. Let's explore the principles of Stoicism and how they can help you build resilience and inner peace. What specific challenges are you currently facing that you’d like to approach with Stoic principles?`
    },
    {
      key: 'buddishm',
      title: 'Buddishm',
      count: "340k",
      desc: `Welcome to your Buddhism session. Let’s explore the teachings of Buddhism and how they can help you find peace and enlightenment. What specific problems are you facing that you’d like to address with Buddhist practices or concepts?`
    },
    {
      key: 'bible',
      title: 'Bible',
      count: "680k",
      desc: `Welcome to your Bible session. Let's delve into the teachings of the Bible and how they can guide and support you. What specific problems or challenges are you facing that you’d like to explore through Bible teachings?`
    },
    {
      key: 'islam',
      title: 'Islam',
      count: "940k",
      desc: `Welcome to your Islam session. Let’s explore the teachings of Islam and how they can provide guidance and support in your life. What specific problems or challenges are you currently facing that you’d like to address with Islamic teachings?`
    },
  ],
};

export default constants[REACT_APP_ENV];
